import React, {useState, useEffect} from 'react'
import Navbar from '../../components/Navbar/Navbar';
import DashboardFilters from '../../components/Filters/DashboardFilters';
import AssignMultipleUsersTable from '../../components/Table/AssignMultipleUsersTable';
import api from '../../api/api';
import Loader from '../../components/Loader/Loader';
import MsgPopup from '../../components/Popup/MsgPopup';
import './AdminAssignMultipleUsers.css'


const AdminAssignMultipleUsers = () => {

    const [displaySidebar, setDisplaySidebar] = useState(true);
    const [train, setTrain] = useState()
    const [coaches, setCoaches] = useState()
    const [ehkUsers, setEhkUsers] = useState()
    const [caUsers, setCaUsers] = useState()
    const [date, setDate] = useState()
    const [showLoader, setShowLoader] = useState(false)
    const [MsgModalflag, setMsgModalFlag] = useState(false)
    const [message, setMessage] = useState('')
    const [modalType, setModalType] = useState('')
    const [upTrainData, setUpTrainData] = useState()
    const [downTrainData, setDownTrainData] = useState()
    const [hasAccess, setHasAccess] = useState(false)

    const [selectedEhk1, setSelectedEhk1] = useState()
    const [selectedEhk2, setSelectedEhk2] = useState()
    const [selectedUsers1, setSelectedUsers1] = useState({})
    const [selectedUsers2, setSelectedUsers2] = useState({})

    const toggleSideBar = () => {
        setDisplaySidebar(!displaySidebar);
    };

    const onSubmit = (train_number, date) => {
        setTrain(train_number)
        setDate(date)
        fetchTrainsCoachWise(train_number,date)
    }

    const fetchcoaches = (train) => {
        if(train){
            api.get(
                `api/coaches-of-train/?trains=${train}`
            ).then(response => {
                setCoaches(response.data.coaches)
            })
        }
    }

    const fetchTrainsCoachWise = (train,date) => {
        setShowLoader(true)
        api.post(
            'api/users/get_Train_access_coach_wise/',
            {
                "train_number": train,
                "date": date
            },
            {
                headers: {
                  'Content-Type': 'application/json',
                    'X-CSRFToken': '{{ csrf_token }}',
                },
            }
        ).then((response) => {
            console.log(response.data)
            // setCoachWiseDict(response.data.coach_wise_dict)
            // setEhkDict(response.data.ehk_dict)
            setUpTrainData(response.data.up)
            setDownTrainData(response.data.down)
            setHasAccess(response.data.has_access)
        }).finally(() => {
            setShowLoader(false)
        })
    }

    const fetchSpecificUsers = (user_type) => {
        api.post(
            'api/users/fetch_specific_users/',
            {
                "user_type": user_type
            },
            {
                headers: {
                  'Content-Type': 'application/json',
                    'X-CSRFToken': '{{ csrf_token }}',
                },
            }
        ).then((response) => {
            if(user_type == "EHK"){
                setEhkUsers(response.data.users)
            }else if(user_type == "coach attendent"){
                setCaUsers(response.data.users)
            }
        })
    }

    const addTrainsCoachWise = async(data, train_number, date_param) => {
        console.log(data)
        setShowLoader(true)
        await api.post(
            'api/users/add_trains_coach_wise/',
            {
                "data": data,
                "train_number": train_number,
                "date": date_param
            },
            {
                headers: {
                  'Content-Type': 'application/json',
                    'X-CSRFToken': '{{ csrf_token }}',
                },
            }
        ).then((response) => {
            console.log(response.data)
            setMessage(response.data.message)
            setModalType("error")
            setMsgModalFlag(true)
        }).finally(() => {
            setShowLoader(false)
            onSubmit(train, date)
        })

    }

    const fetchLastJourneyDate = async (username) => {
        try {
            const response = await api.post(
                'api/users/fetch_last_journey_date/',
                { "user_name": username },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': '{{ csrf_token }}',
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error(`Failed to fetch journey date for ${username}:`, error);
            throw error;
        }
    };

    const removeTrainDetailsOriginDate = (username, coaches, train_number, date_param) => {
        setShowLoader(true)
        api.post(
            'api/users/remove_train_details_by_origin_date/',
            {
                "user_name": username,
                "train_number": train_number,
                "origin_date": date_param,
                "coach_numbers": coaches
            },
            {
                headers: {
                  'Content-Type': 'application/json',
                    'X-CSRFToken': '{{ csrf_token }}',
                },
            }
        ).then((response) => {
            console.log(response.data)
        }).finally(() => {
            setShowLoader(false)
            onSubmit(train, date)
        })
    }

    useEffect(() => {
        fetchcoaches(train)
        fetchSpecificUsers("EHK")
        fetchSpecificUsers("coach attendent")
    }, [train])

    const handleUpchange = (id, value) => {
        console.log(id)
        if(document.querySelector(`#${id}-2`)){
            if(!document.querySelector(`#${id}-2`).disabled){
                document.querySelector(`#${id}-2`).setValue(value)
            }
        }
    }

    const transformData = (data) => {
        const userToCoaches = {};

        console.log(data)

        Object.keys(data).forEach(coach => {
            data[coach].forEach(user => {
                if (!userToCoaches[user]) {
                    userToCoaches[user] = [];
                }
                userToCoaches[user].push(coach);
            });
        });

        return userToCoaches;
    };

    const submitTwoForms = async() => {

        if(upTrainData.coaches){
            const data1 = transformData(selectedUsers1);
            selectedEhk1.forEach((ehk) => {
                data1[ehk] = upTrainData.coaches
            })
            await addTrainsCoachWise(data1, upTrainData.train_no, upTrainData.date);
        }

        if(downTrainData.coaches){
            const data2 = transformData(selectedUsers2);
            selectedEhk2.forEach((ehk) => {
                data2[ehk] = downTrainData.coaches
            })
            await addTrainsCoachWise(data2, downTrainData.train_no, downTrainData.date);
        }
    }

    return(
        <div>
            <MsgPopup flag={MsgModalflag} setFlag={setMsgModalFlag} message={message} modalType={modalType} />
            {showLoader && <Loader />}
            <Navbar toggleSideBar={toggleSideBar}/>
            <div
                style={{
                marginLeft:
                    displaySidebar === true
                    ? window.innerWidth > 991
                        ? '230px'
                        : '0px'
                    : '0px',
                }}
                
            >
                <div className='mt-20' style={{maxWidth: "100vw"}}>
                    {/* <center>
                        <DashboardFilters onSubmit={onSubmit}/>
                    </center> */}
                    <center>
                        <h2>Please use the 'Assign Staff' feature in the RailOps mobile app</h2>
                    </center>
                    {upTrainData && upTrainData.coaches && <center>
                        <AssignMultipleUsersTable 
                            coaches = {upTrainData.coaches}  
                            ehkUsers={ehkUsers} 
                            caUsers={caUsers} 
                            coachWiseDict={upTrainData.coach_wise_dict} 
                            ehkDict={upTrainData.ehk_dict} 
                            date = {upTrainData.date}
                            train = {upTrainData.train_no}
                            down = {false}
                            hasAccess={hasAccess}
                            onSubmit={addTrainsCoachWise} 
                            fetchLastJourneyDate = {fetchLastJourneyDate}
                            removeTrainDetailsOriginDate = {removeTrainDetailsOriginDate}
                            handleUpchange = {handleUpchange}

                            selectedUsers = {selectedUsers1} 
                            setSelectedUsers = {setSelectedUsers1}
                            selectedEhk = {selectedEhk1}
                            setSelectedEhk = {setSelectedEhk1}
                        />
                    </center>}

                    {downTrainData && downTrainData.coaches && <center>
                        <AssignMultipleUsersTable 
                            coaches = {downTrainData.coaches}  
                            ehkUsers={ehkUsers} 
                            caUsers={caUsers} 
                            coachWiseDict={downTrainData.coach_wise_dict} 
                            ehkDict={downTrainData.ehk_dict} 
                            date = {downTrainData.date}
                            train = {downTrainData.train_no}
                            down = {true}
                            hasAccess={hasAccess}
                            onSubmit={addTrainsCoachWise} 
                            fetchLastJourneyDate = {fetchLastJourneyDate}
                            removeTrainDetailsOriginDate = {removeTrainDetailsOriginDate}
                            handleUpchange = {handleUpchange}

                            selectedUsers = {selectedUsers2} 
                            setSelectedUsers = {setSelectedUsers2}
                            selectedEhk = {selectedEhk2}
                            setSelectedEhk = {setSelectedEhk2}
                        />
                    </center>}
                </div>

                {upTrainData && upTrainData.coaches && downTrainData && downTrainData.coaches && hasAccess &&  <center><button type='button' onClick={submitTwoForms} className='btn btn-primary my-4'>Update both trains</button></center>}
            </div>
        </div>
    )
}

export default AdminAssignMultipleUsers;