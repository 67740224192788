import React from 'react';

const Termcondition = () => {
  return (
    <div style={{ fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif', padding: '2em', lineHeight: '1.5', backgroundColor: '#f9f9f9', color: '#666' }}>
      <header style={{ textAlign: 'center', marginBottom: '2em' }}>
        <h1 style={{ fontSize: '2.8em', color: '#333' }}>RailOps</h1>
      </header>

      <section style={{ backgroundColor: '#fff', padding: '2em', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', fontSize: '1.2em', color: '#444' }}>
        <strong>Terms and Conditions</strong>
        <div className='apply-details'>
          <ol className='apply-ollist'>
            <span className='apply-list'>
              <h5 className="semi-h5" style={{ fontWeight: '600', marginTop: '1.5em' }}>Introduction :</h5>
              <div className='apply-about'>
                <p>
                  These Terms and Conditions govern your use of Biputri's RailOps<br/> By accessing this Application, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you must not use this Application.
                </p>
              </div>
            </span>
            <span className='apply-list'>
              <h5 className="semi-h5" style={{ fontWeight: '600', marginTop: '1.5em' }}>Definitions :</h5>
              <div className='apply-about'>
                <span className="semi_list">Client: The person or entity requesting services from Biputri.</span><br/>
                <span className="semi_list">Company: Biputri, the provider of software development and related services.</span><br/>
              </div>
            </span>
            <span className='apply-list'>
              <h5 className="semi-h5" style={{ fontWeight: '600', marginTop: '1.5em' }}>Services :</h5>
              <div className='apply-about'>
                <p>Biputri provides project-based software development services, which include but are not limited to:</p>
                <span className="semi_list">Custom software development</span><br/>
                <span className="semi_list">Web and mobile application development</span><br/>
                <span className="semi_list">Maintenance and support services</span><br/>
                <span className="semi_list">Consultancy</span><br/>
              </div>
            </span>
            <span className='apply-list'>
              <h5 className="semi-h5" style={{ fontWeight: '600', marginTop: '1.5em' }}>Project Acceptance:</h5>
              <div className='apply-about'>
                <p>The Company reserves the right to accept or reject any project at its discretion. Project acceptance will be confirmed in writing (email or contract) and will outline the scope, timeline, and costs involved.</p>
              </div>
            </span>
            <span className='apply-list'>
              <h5 className="semi-h5" style={{ fontWeight: '600', marginTop: '1.5em' }}>Payments :</h5>
              <div className='apply-about'>
                <span className="semi_list"><b>Payment Terms:</b> Payments are due as specified in the project proposal or contract. Failure to pay on time may result in project delays.</span><br/>
                <span className="semi_list"><b>Deposit:</b> A non-refundable deposit may be required to commence work.</span><br/>
                <span className="semi_list"><b>Final Payment:</b> Final payment is due upon project completion and before the final delivery of work.</span><br/>
              </div>
            </span>
            <span className='apply-list'>
              <h5 className="semi-h5" style={{ fontWeight: '600', marginTop: '1.5em' }}>Intellectual Property</h5>
              <div className='apply-about'>
                <span className="semi_list"><b>Ownership: </b>The Company retains ownership of all intellectual property rights in the software until full payment has been received. Upon payment, the intellectual property rights are transferred to the Client as agreed in the contract.</span><br/>
                <span className="semi_list"><b>Third-Party Components:</b> Any third-party components used in the project will remain the property of their respective owners.</span><br/>
              </div>
            </span>
            <span className='apply-list'>
              <h5 className="semi-h5" style={{ fontWeight: '600', marginTop: '1.5em' }}>Confidentiality :</h5>
              <div className='apply-about'>
                <p>Both parties agree to keep all information related to the project confidential. This includes but is not limited to business strategies, technical information, and customer data.</p>
              </div>
            </span>
            <span className='apply-list'>
              <h5 className="semi-h5" style={{ fontWeight: '600', marginTop: '1.5em' }}>Limitation of Liability :</h5>
              <div className='apply-about'>
                <p>The Company will not be liable for any indirect, incidental, or consequential damages arising from the use or inability to use the software, even if the Company has been advised of the possibility of such damages.</p>
              </div>
            </span>
            <span className='apply-list'>
              <h5 className="semi-h5" style={{ fontWeight: '600', marginTop: '1.5em' }}>Warranty</h5>
              <div className='apply-about'>
                <p>The Company warrants that the software will perform substantially according to the specifications outlined in the project proposal. This warranty is valid for 30 days after project completion.</p>
              </div>
            </span>
            <span className='apply-list'>
              <h5 className="semi-h5" style={{ fontWeight: '600', marginTop: '1.5em' }}>Termination</h5>
              <div className='apply-about'>
                <p>Either party may terminate the project by providing written notice. The Client will be responsible for payment of all work completed up to the termination date.</p>
              </div>
            </span>
            <span className='apply-list'>
              <h5 className="semi-h5" style={{ fontWeight: '600', marginTop: '1.5em' }}>Governing Law</h5>
              <div className='apply-about'>
                <p>These Terms and Conditions are governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of these terms will be subject to the exclusive jurisdiction of the courts of [Jurisdiction].</p>
              </div>
            </span>
            <span className='apply-list'>
              <h5 className="semi-h5" style={{ fontWeight: '600', marginTop: '1.5em' }}>Changes to Terms</h5>
              <div className='apply-about'>
                <p>The Company reserves the right to modify these Terms and Conditions at any time. Changes will be effective immediately upon posting to the website. It is the Client’s responsibility to review the Terms and Conditions periodically.</p>
              </div>
            </span>
            <span className='apply-list'>
              <h5 className="semi-h5" style={{ fontWeight: '600', marginTop: '1.5em' }}>Contact Information</h5>
              <div className='apply-about'>
                <p>For any questions or concerns regarding these Terms and Conditions, please contact us at:</p>
                <span className="semi_list"><b>Email:</b> neeta.sinha.biputri@gmail.com</span><br/>
                <span className="semi_list"><b>Phone:</b> +91 9955481699</span><br/>
                <span className="semi_list"><b>Address:</b> PARTHU, Punpun - Sabalpur Rd, Patna, Bihar 804453</span><br/>
              </div>
            </span>
          </ol>
        </div>
      </section>
    </div>
  );
}

export default Termcondition;
