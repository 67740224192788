import React from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import TrainDetails from './screens/TrainDetails/TrainDetails'
import Login from './screens/Login/Login'
import Signup from './screens/Signup/Signup'
import UploadData from './screens/UploadData/UploadData'
import Profile from './screens/Profile/Profile'
import EditProfile from './screens/Profile/EditProfile'
import ChangePassword from './screens/ChangeData/ChangePassword'
import ChangePhone from './screens/ChangeData/ChangePhone'
import ChangeMail from './screens/ChangeData/ChangeMail'
import RequestedUser from './screens/AdminScreens/RequestedUser'
import ForgotPassword from './screens/Login/ForgotPassword'
import PasswordChangeOtp from './screens/ChangeData/PasswordChangeOtp'
import MobileLogIn from './screens/Login/MobileLogIn'
import MapScreen from './screens/MapScreen/MapScreen'
import PrivateRoutes from './PrivateRoutes'
import Dashboard from './screens/Dashboard/Dashboard'
import AdminAssignMultipleUsers from './screens/AdminAssignMultipleUsers/AdminAssignMultipleUsers'
import PrivacyPolicySanchalak from './screens/PrivacyPolicy/PrivacyPolicyRailops'
import Termcondition from './screens/PrivacyPolicy/Termcondition'

// const PrivateRoutes = () => {
//   let auth = localStorage.getItem("userData")
//   return (
//     auth ? <Outlet /> : <Navigate to="/" />
//   )
// }

const AllRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Login/>}/>
      <Route path='/signup' element={<Signup/>}/> 
      <Route path='/ForgotPassword' element={<ForgotPassword/>}/>
      <Route path='/MobileLogIn' element={<MobileLogIn/>}/>
      <Route path='/privacy-policy' element={<PrivacyPolicySanchalak/>}/>
      <Route path='/terms-conditions' element={<Termcondition/>}/>
      <Route element={<PrivateRoutes />}>
        <Route path='/TrainDetails' element={<TrainDetails/>}/>
        <Route path='/upload-data' element={<UploadData/>}/>
        <Route path='/Profile' element={<Profile/>}/>
        <Route path='/EditProfile' element={<EditProfile/>}/>
        <Route path='/ChangePassword' element={<ChangePassword/>}/>
        <Route path='/ChangeMobileNumber' element={<ChangePhone/>}/>
        <Route path='/ChangeMail' element={<ChangeMail/>}/>
        <Route path='/RequestedUser' element={<RequestedUser/>}/>
        <Route path='/PasswordChangeOtp' element={<PasswordChangeOtp/>}/>
        <Route path='/mapScreen' element={<MapScreen/>}/>
        <Route path='/dashboard' element={<Dashboard/>}/>
        <Route path='/AssignUsers' element={<AdminAssignMultipleUsers/>} />
      </Route>
    </Routes>
  )
}

export default AllRoutes